<template>
    <div>
        <strong>Asset Upload</strong>
        <br>
        <div class="form-group">
            <label>Asset</label>
            <Typeahead ref="assetFileHandlerAsset" cdi_type="asset" v-bind:ID.sync="state.referenceID" />
        </div>
        <div class="form-group">
            <label>Type</label>
            <label>
                <input type="radio" name="type" v-model="state.fileTypeID" :value="$cdiVars.FILE_TYPE_ASSET_PHT" />
                Photo
            </label>
            <label>
                <input type="radio" name="type" v-model="state.fileTypeID" :value="$cdiVars.FILE_TYPE_ASSET_FL" />
                File
            </label>
        </div>
        <div class="form-group">
            <label>Title</label>
            <input type="text" v-model="state.fileName" class="form-control" />
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import curbTypeCache from "@/cache/curbType.cache";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "Asset",
        components: {
            Typeahead
        },
        data() {
            return {
                state: fileUploadStore.state,
                cache: {
                    curbTypeCache
                }
            }
        },
        created() {
            fileUploadStore.initialize(this.$cdiVars.FILE_TYPE_ASSET_PHT);
        },
        mounted() {
            this.$refs.assetFileHandlerAsset.$data.object = this.$appStore.state.fileUploadContextData.data.asset_label;
        }
    }
</script>

<style scoped>

</style>